import React, { useRef } from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import "./style.scss"

import ContactModal from "../../../components/contactModal"
import { navigate } from "gatsby"

import WhiteBg from "/static/cases/glassManufacturer/白色底图.png"
import MainImg from "/static/cases/glassManufacturer/主图.jpg"
import SolutionIcon1 from "/static/cases/glassManufacturer/1.svg"
import SolutionIcon2 from "/static/cases/glassManufacturer/2.svg"
import SolutionIcon3 from "/static/cases/glassManufacturer/3.svg"
import SolutionIcon4 from "/static/cases/glassManufacturer/4.svg"

const GlassManufacturer = () => {
  const modalRef = useRef()
  return (
    <Layout>
      <ContactModal ref={modalRef} />
      <Seo
        title="某著名门窗企业智能小件打托装柜SaaS系统"
        keywords="外贸进出口，装箱软件，拼箱软件，切箱算法"
        description="蓝胖子与著名门窗外贸企业合作，快速为其交付一套智能门窗小件打托装柜SaaS系统，高效解决其货物产品出口的小件打托难题。"
      />
      <div className="relative">
        <StaticImage
          src="../case-banner.png"
          alt="某著名门窗企业智能小件打托装柜SaaS系统"
          className="w-full"
        />
        <div className="absolute top-28 left-24 text-white text-3xl">
          成功案例
        </div>
      </div>
      <div>
        <div className="container m-auto">
          <div id="lead">
            <img src={WhiteBg} alt="底图" />
            <div className="giant">
              <h1 className="inline-block">
                某著名门窗企业智能小件打托装柜SaaS系统
              </h1>{" "}
              <span>中外合资企业</span>
              <span>海外地区</span>
              <span>外贸进出口</span>
            </div>
            <div className="services">
              提供的产品与服务：装满满、企业定制化服务、提供智能打托解决方案
            </div>
          </div>
          <div id="project-background">
            <div className="left">
              <div className="title">项目背景</div>
              <div className="para">
                依靠强大的工业实力和工业门类齐全的上下游产业链，中国成为世界的制造业大国，在门窗行业内出口金额远远大于进口金额，2022年中国门窗出口金额为293.74亿元，同比增长8.18%，而近口金额只有6.12亿元。
              </div>
              <div className="para">
                某中外合资门窗外贸企业，主打生产塑钢门窗、玻璃砖门窗、金属门窗等产品来料加工、销售，货物的进出口业务，年产塑钢门窗100万平左右，进出口订单量逐年增加。
              </div>
              <div className="para">
                蓝胖子与该门窗外贸企业合作，快速为其交付一套智能门窗小件打托装柜SaaS系统，高效解决其货物产品出口的小件打托难题。
              </div>
            </div>
            <img src={MainImg} alt="外贸进出口 中外合资企业" />
          </div>
        </div>
        <div id="pain-points">
          <div className="content">
            <div className="title">客户痛点</div>
            <div className="points">
              <div className="point point1">
                北美、欧洲等发达国家也对铝合金门窗产品需求量大，主要集中在住宅建设和维修市场上。针对维修市场及住宅建设配套场景，不少针对门窗小件的订单需要专门出门，涉及产品型号零散，尺寸多样，流程上需进行打托再装柜，人工进行打托规划费时费力
              </div>
              <div className="point point2">
                装柜业务仍停留在传统人工操作阶段，无论规划环节还是结果分发环节都尚未信息自动化，规划过程低效不可控，且结果难以与各部门以及关键联系人共享装柜结果从而完成进一步物流决策。
              </div>
            </div>
          </div>
        </div>
        <div id="solutions">
          <div className="title">解决方案</div>
          <div className="cards">
            <div className="card">
              <div className="card-content">
                <img src={SolutionIcon1} alt="icon" />
                <div className="text">
                  交付门窗小件打托装柜SaaS系统，实现根据门窗尺寸选定托盘尺寸限制高度，生成打托盘方案计算小件数量后，生成对应装柜计划
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-content">
                <img src={SolutionIcon2} alt="icon" />
                <div className="text">
                  常用货物数据库当中可自由增删门窗小件尺寸重量等信息，同时保存已计算过的打托方案，之后计算直接点选
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-content">
                <img src={SolutionIcon3} alt="icon" />
                <div className="text">
                  装柜计划3D展示，同时显示每托盘门窗小件数量
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-content">
                <img src={SolutionIcon4} alt="icon" />
                <div className="text">
                  对应装柜报告详细显示每柜、每托小件数量情况同时实现计划分享后查看
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="benefits">
          <div className="content">
            <div className="title">项目效益</div>
            <div className="cards">
              <div className="card">
                <div className="head">2分钟</div>
                <div className="desc">平均单个订单规划时间</div>
              </div>
              <div className="card">
                <div className="head">5%</div>
                <div className="desc">平均容器装载率提升约</div>
              </div>
              <div className="card">
                <div className="head">3D展示</div>
                <div className="desc">明确具体展示每件小托件信息</div>
              </div>
              <div className="card">
                <div className="head">报告分享</div>
                <div className="desc">装柜计划一键分享各部门或关键联系人</div>
              </div>
              <div className="card">
                <div className="head">快速交付</div>
                <div className="desc">
                  SaaS系统无需打破原有业务系统形态，3周交付系统
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="misc">
          <div className="content">
            <div className="qa">
              <div className="title">其他问题</div>
              <div className="detail">
                告诉我们您需要解决什么问题，专家会帮助您找到最适合您的解决方案
              </div>
              <button
                onClick={() => modalRef.current.openModal()}
                className="contact-us-now"
                gtm-id="cases-contact-us"
              >
                联系我们
              </button>
            </div>
            <div className="try">
              <div className="product">
                <div className="about">开始体验 装满满-精简版</div>
                <div
                  className="go check-zmm-lite"
                  onClick={() =>
                    window.open("https://zmm-lite.doraopt.com?ref=h")
                  }
                >
                  立即体验
                </div>
              </div>
              <div className="product">
                <div className="about">更多产品</div>
                <div
                  className="go check-products"
                  onClick={() => navigate("/products")}
                >
                  查看更多产品
                </div>
              </div>
              <div className="product">
                <div className="about">继续浏览</div>
                <div
                  className="go check-cases"
                  onClick={() => navigate("/cases")}
                >
                  查看更多成功案例
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default GlassManufacturer
